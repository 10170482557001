import { Link } from 'react-router-dom';

function Footer() { 
  return (
    <div className='container-fluid row justify-content-center align-items-center'>
      
      <div className='col-auto'>
      <footer className='mx-3 my-3'>
      <ul className="nav border-bottom justify-content-center">
        <li className="nav-item"><Link to="impressum" className="nav-link px-2 text-muted">Impressum</Link></li>
        <li className="nav-item"><Link to="kontakt" className="nav-link px-2 text-muted">Kontakt</Link></li>
        <li className="nav-item"><Link to="datenschutz" className="nav-link px-2 text-muted">Datenschutz</Link></li>
      </ul>
      <p className="text-center text-muted py-3">© {new Date().getFullYear()} Rakete Freiburg</p>
    </footer>
      </div>
    </div>
    
  );
}

export default Footer;