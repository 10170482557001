import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Titlebar from "../components/Title.jsx";

function Frauen() {
    const [team, setTeam] = useState({});
    const [loading, setLoading] = useState(true);
    const [leaders, setLeaders] = useState([]);
    const [extratraining, setExtratraining] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios.get(`https://server.raketefreiburg.de/api/Teams/66696bb12c116eb84988b07e`)
            .then((response) => {
                setTeam(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/members/summary/all')
            .then((response) => {
                setLeaders(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.get(`https://server.raketefreiburg.de/api/Freizeitkick`)
            .then((response) => {
                const firstItem = response.data.data[0]; // Direkter Zugriff auf den ersten Eintrag
                setExtratraining(firstItem);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);
    return (
        <>
            <Titlebar title={team.name} />
            <div className="container">
                <div className="mt-4">
                    <img src={`https://server.raketefreiburg.de/uploads/${team.picture}`} className="w-100 rounded shadow card-img-top" alt="Teamfoto Frauen"/>
                </div>
                <div className="row">
                    <div className="col-lg">
                        <div>
                            <div className=" text-center fs-6 pt-4 shadow rounded px-4 py-2 mt-4">
                                <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">ABOUT US</p>
                                <p>
                                    {team.about}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="my-2 fs-6 rakete-font text-center bg-rakete text-white rounded shadow-lg">
                                <p className="fw-bold rakete-font pt-5 fs-4">UNSER MOTTO:</p>
                                <p className=" pb-5 fs-5 mx-3">
                                    {team.slogan}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg">
                        <div className="my-auto mx-auto shadow rounded mt-4">
                            <p className="rakete-rot rakete-font fs-6 text-center my-3 pt-3 fw-semibold rounded">TRAININGSZEITEN*</p>
                            {!team.trainings ? (
                                <p>Bitte Warten...</p>
                            ) : (
                                <div className='py-2'>
                                    <div className='text-center fw-semibold rakete-font mb-2 fs-6' colspan="5">SOMMER</div>
                                    <table className="table table-striped">
                                        <thead className='text-center fs-7 rakete-font'>
                                            <tr>
                                                <th>Tag</th>
                                                <th>Uhrzeit</th>
                                                <th>Dauer</th>
                                                <th>Ort</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {team.trainings
                                                .filter(training => training.season)
                                                .sort((a, b) => {
                                                    const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                    return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                })
                                                .map((training) => (
                                                    <tr className="table fs-7" key={training._id}>
                                                        <th>{training.day}</th>
                                                        <td>{training.time} Uhr</td>
                                                        <td>{training.duration} Std</td>
                                                        <td>{training.location}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <div className='text-center fw-semibold rakete-font mb-2 fs-6'>WINTER</div>
                                    <table className="table table-striped">
                                        <thead className='text-center fs-7 rakete-font'>
                                            <tr>
                                                <th>Tag</th>
                                                <th>Uhrzeit</th>
                                                <th>Dauer</th>
                                                <th>Ort</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-center'>
                                            {team.trainings
                                                .filter(training => !training.season)
                                                .sort((a, b) => {
                                                    const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                    return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                })
                                                .map((training) => (
                                                    <tr className="table fs-7" key={training._id}>
                                                        <th>{training.day}</th>
                                                        <td>{training.time} Uhr</td>
                                                        <td>{training.duration} Std</td>
                                                        <td>{training.location}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <p className="fs-7 text-center mx-5 fw-semibold">*Die Trainingszeiten können variieren. Die genauen Zeiten erhaltet ihr von der Leitung.</p>
                            {(!extratraining ? (<p>Bitte Warten...</p>) : (
                                <div className="alert alert-danger border border-0 rounded-0 py-4 shadow-sm">
                                    <div className="rakete-rot rakete-font fw-semibold text-center fs-6 text-uppercase">{extratraining.name}</div>
                                    <div className="mx-4 fs-7 text-center pt-1">Im Sommer heißt es jeden Freitag: <b>Fußball, Freunde, Feierabend</b> – der perfekte Start ins Wochenende mit dem abteilungsübergreifenden {extratraining.name}!</div>
                                    <div className="row mx-4 justify-content-center pt-3 rakete-font fs-7">
                                        <div className="col-auto fw-semibold">{extratraining.day}</div>
                                        <div className="col-auto fw-semibold">{extratraining.start} - {extratraining.end} Uhr</div>
                                        <div className="col-auto">{extratraining.location}</div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <p className="rakete-rot rakete-font fs-6 mt-2 text-center fw-semibold">LEITUNG</p>
                                <div className="row text-center justify-content-center align-items-center border-bottom border-danger pb-3 mx-5">
                                    {leaders
                                        .filter(leader => leader.role === 7)
                                        .map((leader) => (
                                            <div className="col-md-6" key={leader._id}>
                                                {leader.forename} {leader.lastname}
                                            </div>
                                        ))}
                                </div>
                                <p className="text-center fw-semibold py-2 rakete-rot">leitung-frauen(at)rakete-freiburg.de</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Frauen;