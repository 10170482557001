import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const TeamCard = ({ imageSrc, buttonText, link }) => {
    return (
        <div className="col team-card text-center pb-4">
            <div className="button-overlay team-img">
            <Link to={link}>
                <img
                    src={`https://server.raketefreiburg.de/uploads/${imageSrc}`}
                    className="img-thumbnail"
                    alt="..."
                />
                <button
                    type="button"
                    className="btn btn-danger rakete-font"
                >
                    {buttonText}
                </button>
                </Link>
            </div>
        </div>
    );
};

const TeamsSection = () => {
    const [teamData, setTeamData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);
        axios
          .get('https://server.raketefreiburg.de/api/Teams')
          .then((response) => {
            setTeamData(response.data.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }, []);

    return (
        <div className="py-2 bg-rakete shadow">
            <div className="container-fluid ">
                <p className="rakete-font my-1 text-end text-white fw-semibold fs-4">TEAMS</p>
                <div className="row justify-content-center py-4">
                    {teamData.map((team, index) => (
                        <TeamCard
                            key={index}
                            imageSrc={team.picture}
                            buttonText={team.name}
                            link={`/${team.name.replace(/die /i, "").toLowerCase()}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamsSection;
