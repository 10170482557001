import DefaultForm from "../components/form";
import Titlebar from "../components/Title.jsx";
function Sponsoring() {
    return (
        <div>
            <Titlebar title="Sponsoring" />

            <div className="bg-white">
                <div className="container pt-3 px-3 pt-5">
                    <p className="rakete-font fs-4 fw-bold text-center text-uppercase rakete-rot">Werde Sponsor für unseren Verein – Gemeinsam für eine starke Gemeinschaft!</p>
                    <div className="text-justify text-center mx-5">
                        <p>
                            Es ist eine wunderbare Gelegenheit, unseren Verein zu unterstützen. Deine Hilfe ermöglicht es uns, den Sport in unserer Region zu fördern und Menschen zusammenzubringen. Mit deinem Sponsoring ermöglichst du nicht nur die Ausstattung unserer Teams, sondern auch wichtige Projekte und Events, die das Vereinsleben bereichern. Deine Unterstützung schafft direkte Verbindungen und stärkt das Gemeinschaftsgefühl – ein Beitrag, der weit über finanzielle Hilfe hinausgeht.
                        </p>
                        <div className="fw-semibold fs-5 pt-3 pb-2">
                            Als Sponsor bieten wir dir verschiedene Werbeflächen:
                        </div>
                        <div className="text-center fw-semibold fs-5 rakete-rot">
                            <div className="py-1">Trikotsponsoring</div>
                            <div className="py-1">Homepage Werbebanner</div>
                            <div className="py-1">Homepage Footer</div>
                            <div className="py-1">Plakate bei Turnieren</div></div>                      
                    </div>
                    <p className="text-center pt-4 fw-semibold px-3">Schreib uns einfach an, und wir finden gemeinsam die passende Lösung für ein erfolgreiches Sponsoring!</p>
                    <div className="row justify-content-center mx-3 bg-light my-5 rounded shadow">
                        <div className="col-md-6 rounded my-4">
                            
                            <p className="text-center text-uppercase rakete-font fs-3 fw-semibold rakete-rot mb-4">Werde Sponsor</p>
                            <DefaultForm topic={2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sponsoring;