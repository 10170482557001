import DefaultForm from "../components/form";
import Titlebar from "../components/Title.jsx";
function Kontakt() {
    return (
        <div>
            <Titlebar title="Kontakt" />

            <div className="bg-white">
                <div className="container pt-3 px-3 pt-5">
                        <p className="rakete-font fs-5 fw-bold text-center text-uppercase rakete-rot">Du hast Fragen?</p>
                        <div className="text-justify text-center">
                            <p>
                                Wir freuen uns über deine Nachricht! Egal, ob du Fragen hast, Feedback geben möchtest oder dich über unsere Angebote informieren willst – fülle einfach das untenstehende Formular aus und wir melden uns so schnell wie möglich bei dir. Bitte vergiss nicht, alle erforderlichen Felder auszufüllen, damit wir dir bestmöglich weiterhelfen können. Vielen Dank für dein Interesse!
                            </p>
                        </div>
                    <div className="row justify-content-center mx-3 bg-light my-5 rounded shadow">
                        <div className="col-md-6 rounded my-4">
                            <p className="text-center text-uppercase rakete-font fs-3 fw-semibold rakete-rot mb-4">Kontaktformular</p>
                            <DefaultForm topic={0} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kontakt;