import logo from "../assets/logo.png";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";


function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const closeNav = () => setExpanded(false);
  const handleDropdownSelect = () => setExpanded(false);

  return (
    <Navbar expanded={expanded} onToggle={setExpanded} className="navbar sticky-top shadow-sm" expand="md" bg="white" data-bs-theme="light" id="sidebar">
      <Container>
        <div>
          <Link to='/' className="nav-link" onClick={closeNav}>
            <img src={logo} alt="Logo" height="50px" />
          </Link>
        </div>
        <Navbar.Brand className="brand">
          <Link to='/' className='navbar-brand rakete-font fw-light text-dark' onClick={closeNav}>
            <b className="rakete-rot carot-white fw-semibold">RAKETE</b>
            <b className="carot-white fw-semibold">FREIBURG</b>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto text-dark" defaultActiveKey="/home">
            <Nav.Item>
              <NavLink to='' className="nav-link carot-white" onClick={closeNav}>Home</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to='club' className="nav-link carot-white" onClick={closeNav}>Verein</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to='events' className="nav-link carot-white" onClick={closeNav}>Events</NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavDropdown title="Teams" id="teams-dropdown" className="carot-white" onSelect={handleDropdownSelect}>
                <NavDropdown.Item as={NavLink} to='erste' onClick={closeNav}>Erste</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='zweite' onClick={closeNav}>Zweite</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='mixed' onClick={closeNav}>Mixed</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to='frauen' onClick={closeNav}>Frauen</NavDropdown.Item>
              </NavDropdown>
            </Nav.Item>
            <Nav.Item>
              <NavLink to="member" className="nav-link rakete-font text-danger" onClick={closeNav}>MITKICKEN!</NavLink>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
